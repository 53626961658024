import CardFull from 'src/components/cards/card-full';
import CardH from 'src/components/cards/card-h';
import { FiArrowRightCircle } from 'react-icons/fi';
import dynamic from 'next/dynamic';
// import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { APP_IMG } from 'src/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const CardV = dynamic(import('../cards/card-v'));
const DynamicAds = dynamic(import('src/utils/ads/dynamic-ads'));
// const Ads = dynamic(import('src/components/ads'), { ssr: false });

const SectionFullH = ({ data, count = 5, heading = true, include = true, txtSize = '--small', path = '#', banner = false, eventDataLayer, showAds = false, ads }) => {
  const cloneData = include ? [...data.data].splice(0, count) : [...data].splice(0, count);
  const firstData = banner ? cloneData : cloneData.shift();
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  // console.log(showAds);
  return (
    <div className='block-wrapper'>
      {heading && (
        <div className='block-title'>
          <h2>{data.title}</h2>
          <a href={path} className='more'>
            ดูทั้งหมด <FiArrowRightCircle />
          </a>
        </div>
      )}

      <div className='row block-content'>
        <div className='col-12'>
          {banner ? (
            <a
              href='https://www.bitkub.com/signup?ref=2486094'
              target='_blank'
              rel='noreferrer noopener'
              onClick={() => {
                sendDataLayerGlobal({
                  ...eventDataLayer,
                  data: {
                    ...eventDataLayer?.data,
                    title: 'bitkub-banner',
                    index: 1
                  }
                });
              }}
            >
              <img className='bitkub-banner' src={`${APP_IMG}/images/bitkub/banner.jpg`} alt='bitkub banner' width='100%' height='auto' />
            </a>
          ) : (
            // <CardFull
            //   data={firstData}
            //   size={txtSize}
            //   mb={showAds ? '' : 'mb-3'}
            //   //! DATALAYER
            //   eventDataLayer={{
            //     ...eventDataLayer,
            //     data: {
            //       ...eventDataLayer?.data,
            //       title: firstData?.title,
            //       index: 1
            //     }
            //   }}
            // />
            <CardV
              data={firstData}
              description={false}
              badge={true}
              path={firstData?.link}
                //! DATALAYER
                eventDataLayer={{
                  ...eventDataLayer,
                  data: {
                    ...eventDataLayer?.data,
                    title: firstData?.title,
                    index: 1
                  }
                }}
            />
          )}
        </div>
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />}

        <div className='col-12'>
          <ul className='block-list'>
            {cloneData.map((item, index) => (
              <li key={index}>
                <CardH
                  data={item}
                  large={false}
                  //! DATALAYER
                  eventDataLayer={{
                    ...eventDataLayer,
                    data: {
                      ...eventDataLayer?.data,
                      title: item?.title,
                      index: index + 2
                    }
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionFullH;
